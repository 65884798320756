.dropdown-mega {
	box-shadow: rgba(0,0,0,0.3) 0 0 10px;
	border-radius: 5px;
	position: absolute;
	z-index: 1003;
	background-color: white;
	width: 100%;
}

.dropdown-mega__item-container {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	height: 250px;
}

.dropdown-mega.dropdown-mega--hidden {
	display: none;
}

.dropdown-mega .dropdown-mega__item {
	margin: 4px 5px;
	padding: 3px 10px;
	cursor: pointer;
}


.dropdown-mega .dropdown-mega__item:hover {
	background: rgba(0, 0, 0, 0.1);
}