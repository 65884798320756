@import 'gema.css';
@import 'swiper.css';
@import 'typeahead.css';
@import 'dropdown-mega.css';

:root {
    --prada-blue: #19446f;
    --prada-orange: #f9aa75;
    --prada-red: #b51f2b;
    --prada-green: green;
    --prada-dark-orange: #f5952f;
    --prada-dark-blue: #0d243a;
    --prada-dark-blue-shadow: #081725;

    --prada-1: #bc353f;
    --prada-2: #f9b9b7;
    --prada-3: #d7e0e7;
    --prada-4: #6f90af;
    --prada-5: #396185;

    --active-menu-background-color: var(--prada-3);
    --active-menu-text-color: var(--prada-5);

    --hover-menu-background-color: var(--prada-3);
    --hover-menu-text-color: var(--prada-5);
}


.image-bottom {
    position: absolute;
    bottom: 0;
    z-index: 199;
}

.text-1 {
    font-size: 14px;
}

.text-2 {
    font-size: 16px;
}

.text-indent-1 {
    text-indent: 10px;
}

.align-self-end {
    align-self: flex-end;
}

.col-narrow {
    padding: 0 !important;
    margin: 0 !important;
}

.btn-prada {
    background: var(--prada-dark-blue);
    color: white;
    transition: 0.3 all ease-in;
}


.btn-prada:hover {
    background: var(--prada-dark-blue-shadow);
    color: white;
}

.btn-prada-danger {
    background: var(--prada-red);
    color: white;
    transition: 0.3 all ease-in;
}


.btn-prada-danger:hover {
    color: white;
}

.btn-prada-border {
    background: none;
    border: 2px solid var(--prada-dark-blue);
    color: var(--prada-dark-blue);
}

.brd-none {
    border: none !important;
}

/* Header */
section.navigation {
    background-color: #19446f;
    color: white;
    padding: 0px;
}

section .navbar.navbar-expand-lg.navbar-dark {
    padding: 0px;
}

section .navbar.navbar-expand-lg.navbar-dark  a {
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 0px;
}

.navigation a {
    color: white;
}

.navigation a:hover {
    background-color: var(--prada-4);
    /*color: rgb(199, 199, 199);*/
}

.logo {
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url("../storage/images/logo.png");
    display: block;
    height: 70px;
    width: 400px;
}


/*Hero Image*/
.hero-image {
    margin-top: 40px;
}

.hero-image__image, 
.hero-image__image:hover,
.hero-image__image:active {
    display: flex;
    position: relative;
    color: var(--prada-dark-blue);
    text-decoration: none;
}

.hero-image__image.hero-image__image--reverse {
    flex-direction: row-reverse;
}

.hero-image__island-image {
    background-image: url("../images/prada-island-image.png");
    background-size: contain;
    background-repeat: no-repeat;
    height: 400px;
    z-index: 98;
}

.hero-image__prologue {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 99;
}

.hero-image__description {
    font-size: 18px;
    word-spacing: 5px;
}

.hero-image__caption {
    color: white;
    font-size: 70px;
    font-weight: bolder;
    margin-right: 70px;
}

.hero-image__block {
    height: 70px;
    width: 100%;
    background: var(--prada-dark-blue);
    position: absolute;
    z-index: 1;
}

.hero-image__indonesia-island {
    height: 100px;
    position: absolute;
    bottom: 0;
}

.hero-image__cta {
    align-self: flex-start;
}

.hero-image__texts {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 75px;
    min-height: 300px;
    color: var(--prada-dark-blue);
}


.hero-image__texts--reverse {
    padding-left: 100px;
}

/* Statistics */
.statistics {
    margin-bottom: 3rem;
}
.statistics__title {
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 2rem;
}

.statistics__nav {
    margin-bottom: 40px;
}

.statistics__nav .nav-item a {
    border-radius: 100px;
    background-color: #072f48a3;
    color: white;
    margin: 0 10px;
    font-size: 18px;
}

.statistics__nav .nav-item a.active {
    background-color: #072f48;
}


/* Indicator Card */
.indicator-card {
    display: block;
    box-shadow: #9c9c9c 0 1px 20px -6px;
    padding: 1rem;
}

.indicator-card__city {
    margin-bottom: 7px;
}

.indicator-card__city-name {}
.indicator-card__city-percentage {}
.indicator-card__city-percentage.indicator-card__city-percentage--minus {
    color: var(--prada-red);
}

.indicator-card__city-percentage.indicator-card__city-percentage--plus {
    color: var(--prada-green);
}

.indicator-card__value {
    font-family: 'Merriweather', serif;
    font-weight: 600;
    font-size: 1.3rem;
}


.indicator-card__arrow.indicator-card__arrow--up {
    color: var(--prada-green);
}
.indicator-card__arrow.indicator-card__arrow--down {
    color: var(--prada-red);
}


/* Analysis */
.analysis {
    background: var(--prada-blue);
    padding: 20px 0px 50px 0px;
    font-family: 'Ubuntu', serif;
    margin-bottom: 40px;
}

.analysis__title {
    text-transform: uppercase;
    text-align: center;
    color: var(--prada-orange);
    margin-bottom: 40px;
}

.analysis .card {
    border: none;
    background: none;
    color: white;
    font-size: 0.8rem;
}

.analysis .card-title {
    font-size: 1.2rem;
}


.analysis .card-image {
    margin-bottom: 10px;
}

.blog-posts {
     font-family: 'Ubuntu', serif;
     margin-bottom: 40px;
}

.blog-posts .card {
    text-decoration: none;
    cursor: pointer;
    text-decoration: none;
    color: inherit;
}

.blog-posts .card-title,
.blog-posts .card-text {
    padding: 0 10px;
}

.blog-posts .card-title {
    margin-top: 10px;
}

.blog-posts .timestamp {
    position: absolute;
    bottom: 0;
    right: 0;
}

.blog-posts .blog-posts__description {
    margin-bottom: 40px;
}

/* Contact us */
.contact-us {
    background-image: url("../images/bappenas building.jpg");
    padding: 50px 0;
}

.box {
    position: relative;
    border-radius: 10px;
    background: #fff;
    padding: 40px;
    box-sizing: border-box;
    box-shadow: 0 5px 10px rgba(0,0,0,.2);
}



/* Footer */
.footer {
    padding: 40px 0;
    background: #f9f9f9;
}




.dashboard {
}

#dashboard-content {
    margin-top: 20px;
    background: white;
    padding: 0;
    padding-top: 30px;
    padding-bottom: 30px;
    border-radius: 5px;
}

/* #dashboard-tematik {
    margin-bottom: 50px
} */

#dashboard-statistics {
    transition: 0.3s all ease-in;
    margin-bottom: 15px;
    position: relative;
}

#dashboard-statistics.sticky {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1015;
    margin-top: 0;
}

#dashboard-statistics.sticky #province-form {
    padding: 0;
    margin: 0 !important;
}  

.dashboard__image {
    /*background: url("../images/hero-image-overlay.png");*/
    /*background-size: cover;*/
    color: white;
    font-family: 'Ubuntu', serif;
}

.dashboard__title {
    color: var(--prada-dark-orange);
    font-size: 40px;
    font-weight: bolder;
}

.dashboard-statistics {
    background: var(--prada-dark-blue);
    /*box-shadow: 1px 1px 4px var(--prada-dark-blue-shadow);*/
    color: white;
    text-align: center;
    /*padding: 15px;*/
    font-weight: bolder;
    border-top: 5px solid white;
    border-bottom: 5px solid white;
}

.dashboard-statistics__item {
    border-right: 1px solid rgba(255,255,255,0.8);
}

.dashboard-statistics__item h5 {
    font-size: 14px;
}

.dashboard-statistics .dashboard-statistics__item-value {
    font-size: 14.5px;
}

.dashboard-statistics .dashboard-statistics__title {
    font-size: 14px;
}

.dashboard-statistics .dashboard-statistics--center {
    padding-left: 15px;
    padding-right: 15px;
}

.dashboard-statistics .dashboard-statistics__indicator {
    padding: 15px;
    background: var(--prada-dark-blue);
}

.dashboard-statistics .dashboard-statistics__year {
    padding-top: 10px;
}

.dashboard-statistics .dashboard-statistics__values {
    padding-bottom: 15px;
}



.chart-image {
    display: block;
    text-align: center;
    padding: 10px;
}

.chart-table {
    padding: 10px;
}

.chart-table .table-dark {
    background-color: var(--prada-dark-blue);
    border: none;
}

.chart-table .table-dark thead {
    background-color: var(--prada-dark-orange);
    border: none;
}

.about {
    margin-top: 50px;
    margin-bottom: 40px;
}

.dropdown-menu.drop {
    background-color: #19446f;
    color: white;
}

.analisis-faq {
    min-height: 50vh;
    background: url("../images/bappenas-building.jpg");
    padding: 40px 0;
    font-family: 'Merriweather', serif;
    line-height: 21pt;
}


.analisis-faq__content {
    background: white;
    border-radius: 20px;
    box-shadow: 0px 10px 10px gray;
    padding: 0 60px;
}

ul.breadcrumb {
    padding: 10px 0;
    list-style: none;
    background-color: rgba(0,0,0,0);
  }
  ul.breadcrumb li {
    display: inline;
    font-size: 14px;
  }
  ul.breadcrumb li+li:before {
    padding: 8px;
    color: black;
    content: "/\00a0";
  }
  ul.breadcrumb li a {
    color: #0071bc;
    text-decoration: none;
  }
  ul.breadcrumb li a:hover {
    color: #01447e;
    text-decoration: underline;
  }

.a-styling-analisis-tematik a {
    text-decoration: none;
    color: #212529;
}

.card-title-analisis-tematik {
    padding-right: 40px;
    padding-left: 15px;
    padding-bottom: 10px;
    line-height: 25px;
}

.card-title-analisis-tematik:hover {
    text-decoration: underline;
}

.card-text-analisis-tematik {
    padding-right: 40px;
    padding-left: 15px;
    padding-top: 20px;
    margin-bottom: 5px;
}

.card-border {
    box-shadow: 0 1px 2px 1px #e5e5e5;
}

/* The Modal (background) */
.modal-analisis-tematik {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  /* Modal Content/Box */
  .modal-analisis-tematik-content {
    font-family: 'Merriweather', serif;
    background-color: #fefefe;
    margin: 13% auto; /* 15% from the top and centered */
    padding: 0 20px;
    border: 1px solid #888;
    width: 50%; /* Could be more or less, depending on screen size */
    border-radius: 15px;
    color: #181818;;
  }

  .modal-analisis-tematik-article {
    padding: 70px 50px;
    font-size: 90%;
  }

  .modal-analisis-tematik-article img {
    width: 100%;
    border: 1px solid #dbdbdb;
  }
  
  /* The Close Button */
  .modal-analisis-tematik-close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
  .modal-analisis-tematik-close:hover,
  .modal-analisis-tematik-close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

.img-analisis-tematik {
    width: 100%;
}

.toc-analisis-tematik {
    margin-top: 42px;
    line-height: 45px;
}

.ul-analisis-tematik {
    list-style: none;
    padding-left: 25px;
}

.ul-analisis-tematik a {
    text-decoration: none;
    color: #0071bc;
}

.ul-analisis-tematik a:hover {
    font-weight: bold;
}

.analisis-faq__faqs {
    padding-bottom: 100px;
}

.analisis-faq__content .card {
    border: none;
    background: none;
    border-bottom: 1px solid lightgray;
}

.analisis-faq__content .card-header {
    background :none;
}

.analisis-faq__content .card-body {
    line-height: 1.7;
    font-size: 15px;
    text-indent: 45px;
}

.analisis-faq__header {
    display: flex;
    flex: 1 0 0%;
    padding: 50px;
}

.analisis-faq__header .analisis-faq__image {
    width: 18%;
    height: 18%;
}

.analisis-faq .analisis-faq__prologue {
    margin-left: 40px;
}

.analisis-faq .analisis-faq__prologue h1 {
    text-transform: capitalize;
}


.dropdown-dashboard .dropdown-menu-center {
    left: 20% !important;
    right: auto !important;
    text-align: center !important;
    transform: translate(-20%, 0) !important;
  }

.dropdown-dashboard .dropdown.megamenu-li {
	position: static;
}

.dropdown-dashboard .dropdown-menu.megamenu {
	position: absolute;
	width: 100%;
	left: 0;
	right: 0;
	padding: 15px;
}



/* .dropdown-dashboard #dashboard,
.dropdown-tentang #tentang {
    background: none;
} */

.dropdown-tentang  #tentang:hover,
.dropdown-bantuan  #bantuan:hover {
    background: var(--prada-4);
}

.dropdown-dashboard .dropdown-item,
.dropdown-tentang .dropdown-item,
.dropdown-bantuan .dropdown-item {
    transition: 0.15s ease-in;
}

.dropdown-dashboard .dropdown-item:hover,
.dropdown-tentang .dropdown-item:hover,
.dropdown-bantuan .dropdown-item:hover,
.dropdown-tentang .dropdown-item.active,
.dropdown-bantuan .dropdown-item.active {
    background: var(--hover-menu-background-color);
    color: var(--hover-menu-text-color);
}

.dropdown-dashboard .dropdown-header,
.dropdown-tentang .dropdown-header,
.dropdown-bantuan .dropdown-header {
    font-weight: bolder;
    color:  white;
}

.prada-main-nav .nav-link.active {
    background: var(--active-menu-background-color); 
    color: var(--active-menu-text-color);
}

.prada-main-nav .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    background: var(--prada-3);
    color: var(--prada-5);
}

.prada-main-nav .nav-pills .nav-link.active, .nav-pills .show>.nav-link:hover {
    color: white;
}

.blog-post {
    margin-top: 30px;
    margin-bottom: 40px;
}



/*Profil Daerah*/
.profil-daerah {
    margin-top: 50px;
    margin-bottom: 100px;
}

.profil-daerah__daerah {
    margin-top: 30px;
}

.profil-daerah__daerah-text {
    font-weight: bolder;
}

.profil-daerah-card,
.profil-daerah-card:hover {
    text-decoration: none;
    color: black;
}

.profil-daerah-card {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

img.profil-daerah-card__image {
    border: none;
    height: 150px;
}

.profil-daerah-card__text {
    font-size: 16px;
    text-align: center;
}


.data-explore {
    margin-top: 50px;
    margin-bottom: 300px;
}
.explore {
    background: #f4f4f4;
    padding: 30px;
}

.explore__selection {
    margin-bottom: 25px;
}
.explore__checkbox{
    margin-bottom: 50px;
}
.explore__checkbox label {
    margin-right: 20px;
}


.data-explore__chart-options {
    padding: 10px;
}

.data-explore__data-select:hover {
    cursor: pointer;
}
.data-explore__data-select {
    margin-bottom: 30px;
    border: 1px solid white;
    border-radius: 100%;
    position: relative;
    margin: 0 5px;
}

.data-explore__data-select img {
    height: 55px;
}

.data-explore__data {
    display: flex;
    margin-bottom: 35px;
}

.data-explore__diagram {
    display: flex;
    margin-bottom: 10px;
}

.data-explore__diagram-select {
    border: 2px solid lightgray;
    background: white;
    border-radius: 10%;
    padding: 5px;
    margin: 0 5px;
    cursor: pointer;
}

.data-explore__diagram-select  img {
    max-width: 100%;
}

/* about */
#apa-itu-prada h2, #tujuan-prada h2, #tentang-kami h2 {
    color: #19446f;
}


.publication__document:hover,
.publication__document:active {
    color: var(--prada-dark-blue);
    text-decoration: none;
    transform: scale(101%);
}

.publication__document {
    box-shadow: #9c9c9c 0 1px 20px -6px;
    transition: all 0.1s ease-in;
    text-decoration: none;
    cursor: pointer;
    color: var(--prada-dark-blue);
    border-radius: 15px;
}

.publication__container {
    margin: 0 -100px;
    padding: 20px 100px;
}

.typeahead {
    z-index: 1051;
}

.input-group > .twitter-typeahead {
    flex: 1 1 auto;
    width: auto;
 }
 
.swiper-container {
    height: 100%;
    width: 100%;
}

.carousel-control-prev,
.carousel-control-next {
    z-index: 200;
    width: 5%;
}



.sidebar-makro {
    margin-top: 10px;
    width: 250px;
    transition: 0.3s all ease-out;
    position: sticky;
    position: -webkit-sticky;
    top: 70px;
}

.sidebar-makro .list-group-item.active {
    background: var(--prada-dark-blue);
}

.data-table {
    min-height: 400px;
}

#tabledata {
    margin-top: 50px;
}

#metodologi {
     background-image: url("../images/bappenas building.jpg");
     padding-top: 30px;
     padding-bottom: 30px;
}

.metodologi__container {
    background: white;
}

.no-wrap {
    white-space: nowrap;
}

.dashboard-tematik-sidebar {
    width: 20%;
    padding-top: 2%;
    background-color: #19446f;
}

.dashboard-tematik-text {
    text-align: left;
}

.dashboard-tematik-card {
    background-color: #19446f;
}

.dashboard-tematik-card-icon {
    color: #bc353f;
}

.dashboard-tematik-map {
    width: 80%;
    padding: 2%;
}

.dashboard-tematik-container {
    display: flex;
}

.dashboard-tematik-border {
    
}


.bappenas-background {
    background: url(/images/bappenas-building.jpg?895b625cfd84d6dbe0fe8c3fd389a657);
    padding-top: 30px;
    padding-bottom: 30px;
}


.background-white {
    background: white;
}


.loading-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    background: rgba(255,255,255,0.8);
    z-index: 1001;
}


.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.loader {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid var(--prada-dark-blue);
  border-right: 1.1em solid var(--prada-dark-blue);
  border-bottom: 1.1em solid var(--prada-dark-blue);
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}



.makro-banner {
    margin-bottom: 50px;
    position: relative;
    z-index: 1003;
    display: flex;
    align-items: center;
    background-color: white;
    border-bottom-left-radius: 152px;
    padding-left: 75px;
    padding-top: 25px;
    justify-content: space-between;
    transform: translateY(3%);
}

.makro-banner .makro-banner__container {
    display: flex;
    justify-content: space-between;
}

.makro-banner .makro-banner__heading {
    font-size: 22px;
    font-weight: bold;
    color: var(--prada-dark-blue);
    margin-left: 50px;
}

.makro-banner .makro-banner__image {
    background-image: url('../images/new-home/Pedesaan dan Perkotaan.png');
    min-width: 50%;
    height: 100px;
    background-size: contain;
    background-repeat: no-repeat;
}

#statistics {
    margin-bottom: 40px;
}
.home-statistics {
    background-color: #006687;
    margin: 0 auto;
    position: relative;
}

.home-analysis {
    display: flex;
    flex-direction: column;
    padding: 50px 0;
    position: relative;
}

.home-statistics::before {
    opacity: 0.3;
    content: ' ';
    background-image: url('../images/new-home/map-01.png');
    background-size: 150%;
    position: absolute;
    top: -200px;
    right: 0px;
    bottom: 0px;
    left: -200px;
    background-repeat: no-repeat;
}

.statistic-card {
    display: flex;
    flex-direction: column;
    background: white;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    padding-top: 70px;
    margin: 0 10px;
}

.statistic-card .statistic-card__icon {
    height: 85px;
    width: 85px;
    border: 4px solid white;
    border-radius: 100%;
    background-color: white;
    position: absolute;
    top: -25px;
}

.statistic-card .statistic-card__header {
    margin-bottom: 15px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}


.statistic-card .statistic-card__body {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.statistic-card .statistic-card__list {
    font-size: 14px;
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    /*padding: 0 15px;*/
    padding-bottom: 15px;
}

.statistic-card .statistic-card__list-item {
    display: flex;
    justify-content: space-between;
}

.statistic-card .statistic-card__list-item.statistic-card__list-item--divider {
    border-bottom: 2px solid #b51f2b;
    margin: 5px 0;
}

.col.statistic-card {
    padding-left: 10px;
    padding-right: 10px;
}


/*Home Tematik Section*/
#home-tematik {
    background: white;
    margin-bottom: 50px;
}
.home-tematik {}
.home-tematik__header {
    text-align: center;
    color: var(--prada-dark-blue);
}


.footer-logo {
    height: 100px;
    width: 100%;
}

.carusell-item--prada {
    display: flex;
    flex-direction: row;
}

.carousel-item--background {
    background-repeat: no-repeat;
    background-size: contain;
}


.carousel-control-prev-icon.carousel-control-prev-black-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon.carousel-control-next-black-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.popup-container {
    width: 70px;
}

/*.popup-container-kab {
    width: 120px;
}
*/