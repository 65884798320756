
.swiper-button-prev {
	color: var(--prada-dark-blue);
	transition: all 0.3s ease-out;
}

.swiper-button-prev:hover {
	transform: translateX(-15%);
}

.swiper-button-next {
	color: var(--prada-dark-blue);
	transition: all 0.3s ease-out;
}

.swiper-button-next:hover {
	transform: translateX(15%);
}
